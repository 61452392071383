import { Locale } from '../model/enums/locale.js';
import routeTranslations from '../assets/json/route-translations.js';

const fallbacksForEnAt = ['hr', 'hu', 'rs', 'ba', 'me', 'ro', 'bg', 'gr', 'sk', 'cz'];
// List of common crawler User-Agent substrings
const crawlers = [
  'bot',
  'crawl',
  'slurp',
  'spider',
  'mediapartners',
  'adsbot',
  'googlebot',
  'bingbot',
  'yandex',
  'duckduckbot',
  'baiduspider',
  'facebot',
  'ia_archiver',
  'ahrefs',
  'semrushbot',
  'majestic',
  'screaming frog',
  'python-requests',
];

export class Utility {
  static isDevEnvironment = () => {
    return window.location.origin.includes('localhost');
  };

  static isClientCrawler = () => {
    // Convert User-Agent string to lowercase for case-insensitive comparison
    const userAgentLower = window.navigator.userAgent.toLowerCase();

    // Check if any of the crawler substrings are present in the User-Agent string
    for (let crawler of crawlers) {
      if (userAgentLower.includes(crawler)) {
        return true;
      }
    }

    return false;
  };

  /**
   * fetches the locale of the client from the strapi
   * @returns {Promise<string>} the locale of the client
   */
  static fetchLocale = async (defaultLocale = 'en') => {
    console.log('HIER');
    const { ip } = await fetch('https://api.ipify.org?format=json').then((x) => x.json());
    console.log('IP', ip);
    const { countryCode, region } = await fetch(`https://strapi-qrailing.azurewebsites.net/api/ipredir?${ip}`).then(
      (response) => response.json(),
    );
    console.log('COUNTRY', countryCode, 'REGION', region);

    let locale = Utility.getLocaleBestFit(`${countryCode}-${region}`, defaultLocale);

    if (fallbacksForEnAt.includes(locale)) {
      locale = 'en-at';
    }

    return locale;
  };

  static loadScript = (url) => {
    // check if the script is already loaded
    const scripts = [...document.getElementsByTagName('script')];
    if (scripts.some((script) => script.src === url)) return Promise.resolve();

    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = url;
      script.onload = resolve;
      script.onerror = reject;
      document.body.appendChild(script);
    });
  };

  /**
   *
   * @param {string} locale
   * @returns
   */
  static getLocaleBestFit = (locale, defaultLocale = 'en') => {
    console.log('LOCALE', locale);
    console.log('DEFAULT', defaultLocale);
    if (!locale || !locale.match(/\w\w[_-]\w\w/gim)) return defaultLocale;
    const [country, region] = locale.toLowerCase().split(/-|_/);
    const validLocales = Object.keys(Locale).map((x) => x.toLowerCase());
    //const countryLocales = validLocales.filter((x) => x.startsWith(country));
    //Mate -- starts with? -- Giving me a Debug-Headache :-D

    const countryLocales = validLocales.filter((x) => x.endsWith(country));

    // test if there is already a fit
    if (countryLocales.length === 0) return defaultLocale;
    if (countryLocales.length === 1) return countryLocales[0];

    // get the best region fit
    const regionLocales = countryLocales.filter((x) => x.toLowerCase().endsWith(region));
    if (regionLocales.length === 1) return regionLocales[0];

    // no fit found, country was, find country default locale
    return countryLocales.find((x) => x.toLowerCase().endsWith(country)) ?? countryLocales[0] ?? defaultLocale;
  };

  static isLocaleValid = (locale) => {
    locale = locale?.toLowerCase();
    return locale && !!Locale[locale];
  };

  static joinRoute = (routes, query) => {
    query ??= {};
    if (typeof routes === 'string') return routes;
    if (!Array.isArray(routes)) throw new Error('Invalid routes provided');
    if (typeof query !== 'object') throw new Error('Invalid query provided');
    return (
      routes
        .flatMap((x) => x.split('/'))
        .filter((x) => x && x !== '')
        .join('/') +
      '?' +
      Object.keys(query)
        .map((x) => `${x}=${query[x]}`)
        .join('&')
    );
  };

  static formatLocale = (locale) => {
    if (!locale) throw new Error('Invalid locale provided');
    locale = locale.toLowerCase().split(/-|_/).join('-');
    const [country, region] = locale.split('-');
    if (region) {
      return `${country}-${region.toUpperCase()}`;
    }
    return country;
  };

  static downloadFile = (url, filename) => {
    if (!url) {
      throw new Error('Link is required');
    }
    if (!filename) {
      throw new Error('Filename is required');
    }
    if (url instanceof Blob) {
      url = URL.createObjectURL(url);
    }
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.click();
  };

  static convertTransparentPixelsToWhite = (dataURL) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = 'Anonymous';
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = img.width;
        canvas.height = img.height;

        // Fill the canvas with white background
        ctx.fillStyle = 'white';
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        // Draw the image on top of the white background
        ctx.drawImage(img, 0, 0);

        resolve(canvas.toDataURL());
      };
      img.onerror = (err) => {
        reject(err);
      };
      img.src = dataURL;
    });
  };

  /**
   * Resizes an image to a specific aspect ratio and centers it on a canvas.
   * @param {string} dataUrl - The data URL of the image.
   * @param {number} desiredAspectRatio - The desired aspect ratio (width / height).
   * @returns {string} - The data URL of the resized image on the canvas.
   */
  static resizeImageToAspectRatio = (dataUrl, desiredAspectRatio) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        const imgAspectRatio = img.width / img.height;

        let newWidth;
        let newHeight;
        if (imgAspectRatio > desiredAspectRatio) {
          newWidth = img.width;
          newHeight = img.width / desiredAspectRatio;
        } else {
          newHeight = img.height;
          newWidth = img.height * desiredAspectRatio;
        }

        const canvas = document.createElement('canvas');
        canvas.width = newWidth;
        canvas.height = newHeight;
        const ctx = canvas.getContext('2d');

        ctx.fillStyle = 'white';

        ctx.fillRect(0, 0, newWidth, newHeight);

        let x;
        let y;

        if (imgAspectRatio > desiredAspectRatio) {
          x = 0;
          y = (newHeight - img.height) / 2;
        } else {
          x = (img.width - newWidth) / 2;
          y = 0;
        }

        // Draw the resized image on the canvas
        ctx.drawImage(img, x, y, img.width, img.height);

        resolve(canvas.toDataURL());
      };

      img.onerror = (error) => reject(error);

      img.src = dataUrl;
    });
  };

  static translateRoute = (route, locale = undefined, doNotFilterLocales = false) => {
    locale ??= window.location.pathname.split('/')[1];
    if (!Utility.isLocaleValid(locale)) throw new Error('Invalid locale provided: ' + locale);
    const ogRoute = route;
    route = route
      .split('/')
      .filter((x) => x && x !== '')
      .join('/');
    if (!doNotFilterLocales && Utility.isLocaleValid(route.split('/')[0])) {
      route = route.split('/').slice(1).join('/');
    }

    const parts = route.split('/');
    let current = routeTranslations[locale];
    route = '';
    for (const part of parts) {
      if (!current[part]) throw new Error('Invalid route provided: ' + ogRoute);
      current = current[part];
      route += (current.__name ?? part) + '/';
    }

    return '/' + locale + '/' + route;
  };

  static addSizeTextToImage = async (dataUrl, textProperties, styleProperties = {}) => {
    let { padding, fontSize, fillColor } = styleProperties;

    // default fill color is transparent
    fillColor ??= '#ffffff00';

    if (!padding) padding = 0;
    if (!fontSize) fontSize = 16;
    if (typeof padding === 'number') padding = { top: padding, right: padding, bottom: padding, left: padding };
    if (typeof fontSize === 'number') fontSize = { top: fontSize, right: fontSize, bottom: fontSize, left: fontSize };

    const { top: topText, right: rightText, bottom: bottomText, left: leftText } = textProperties;
    let { top: topPadding, right: rightPadding, bottom: bottomPadding, left: leftPadding } = padding;
    let { top: topFontSize, right: rightFontSize, bottom: bottomFontSize, left: leftFontSize } = fontSize;

    leftPadding ??= 0;
    topPadding ??= 0;
    rightPadding ??= 0;
    bottomPadding ??= 0;

    leftFontSize ??= 16;
    topFontSize ??= 16;
    rightFontSize ??= 16;
    bottomFontSize ??= 16;

    const tempCanvas = document.createElement('canvas');
    const tempCtx = tempCanvas.getContext('2d');

    tempCtx.font = `${topFontSize}px Arial`;
    const { width: topTextWidth } = topText ? tempCtx.measureText(topText) : { width: 0, hangingBaseline: 0 };

    tempCtx.font = `${bottomFontSize}px Arial`;
    const { width: bottomTextWidth, hangingBaseline: bottomTextHeight } = bottomText
      ? tempCtx.measureText(bottomText)
      : { width: 0, hangingBaseline: 0 };

    tempCtx.font = `${leftFontSize}px Arial`;
    const { width: leftTextWidth } = leftText ? tempCtx.measureText(leftText) : { width: 0, hangingBaseline: 0 };

    tempCtx.font = `${rightFontSize}px Arial`;
    const { width: rightTextWidth } = rightText ? tempCtx.measureText(rightText) : { width: 0, hangingBaseline: 0 };

    const img = await Utility.loadImage(dataUrl);
    const originalWidth = img.width;
    const originalHeight = img.height;

    const canvasWidth = Math.max(originalWidth, topTextWidth, bottomTextWidth) + leftPadding + rightPadding + 2;
    const canvasHeight = Math.max(originalHeight, leftTextWidth, rightTextWidth) + topPadding + bottomPadding + 2;

    const canvas = document.createElement('canvas');
    canvas.width = canvasWidth;
    canvas.height = canvasHeight;

    const ctx = canvas.getContext('2d');
    ctx.fillStyle = fillColor;
    ctx.fillRect(0, 0, canvasWidth, canvasHeight);

    ctx.textBaseline = 'top';
    ctx.fillStyle = 'black';

    ctx.drawImage(img, topPadding, leftPadding, originalWidth, originalHeight);

    if (topText) {
      ctx.font = `${topFontSize}px Arial`;
      ctx.textAlign = 'center';
      ctx.fillText(topText, canvasWidth / 2, 1);
    }

    if (bottomText) {
      ctx.font = `${bottomFontSize}px Arial`;
      ctx.textAlign = 'center';
      ctx.fillText(bottomText, canvasWidth / 2, canvasHeight - bottomTextHeight - 1);
    }

    if (leftText) {
      ctx.font = `${leftFontSize}px Arial`;
      ctx.save();
      ctx.translate(1, canvasHeight / 2);
      ctx.rotate(-Math.PI / 2);
      ctx.textAlign = 'center';
      ctx.fillText(leftText, 0, 0);
      ctx.restore();
    }

    if (rightText) {
      ctx.font = `${rightFontSize}px Arial`;
      ctx.save();
      ctx.translate(canvasWidth - 1, canvasHeight / 2);
      ctx.rotate(Math.PI / 2);
      ctx.textAlign = 'center';
      ctx.fillText(rightText, 0, 0);
      ctx.restore();
    }

    const newDataUrl = canvas.toDataURL();
    return newDataUrl;
  };

  static loadPdfAsImage = async (url) => {
    await this.loadScript('https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.5.207/pdf.min.js');
    window.pdfjsLib.GlobalWorkerOptions.workerSrc =
      'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.5.207/pdf.worker.min.js';
    const loadingTask = window.pdfjsLib.getDocument(url);
    const pdf = await loadingTask.promise.catch();
    if (!pdf) return;
    const page = await pdf.getPage(1).catch();
    if (!page) return;
    const viewport = page.getViewport({ scale: 1.0 });

    // Prepare canvas using PDF page dimensions
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    canvas.height = viewport.height;
    canvas.width = viewport.width;

    const renderContext = {
      canvasContext: context,
      viewport: viewport,
    };

    await page.render(renderContext).promise;

    return canvas.toDataURL('image/png');
  };

  static getQRCode = async (url, width, height) => {
    await Utility.loadScript('https://cdn.jsdelivr.net/npm/qrcodejs@1.0.0/qrcode.min.js');
    const qrElement = document.createElement('div');
    qrElement.style.display = 'none';
    document.body.appendChild(qrElement);
    // eslint-disable-next-line no-undef
    const qrCode = new QRCode(qrElement, {
      text: url,
      width: width,
      height: height,
      colorDark: '#000000',
      colorLight: '#ffffff',
      // eslint-disable-next-line no-undef
      correctLevel: QRCode.CorrectLevel.H,
    });

    return new Promise((resolve) => {
      qrCode._oDrawing._elImage.onload = function () {
        resolve(qrCode._oDrawing._elImage.src);
      };
    });
  };

  static removeEmptyFrameFromImage = async (dataURL, minValue = 240) => {
    const img = new Image();
    img.src = dataURL;

    await new Promise((resolve) => {
      img.onload = resolve;
    });

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    canvas.width = img.width;
    canvas.height = img.height;
    ctx.drawImage(img, 0, 0);

    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const data = imageData.data;

    let top = 0;
    let bottom = canvas.height - 1;
    let left = 0;
    let right = canvas.width - 1;

    const isWhite = (x, y) => {
      const index = (y * canvas.width + x) * 4;
      return (
        (data[index] > minValue &&
          data[index + 1] > minValue &&
          data[index + 2] > minValue &&
          data[index + 3] > minValue) ||
        data[index + 3] < 10
      );
    };

    // Find top boundary
    for (let y = 0; y < canvas.height; y++) {
      let found = false;
      for (let x = 0; x < canvas.width; x++) {
        if (!isWhite(x, y)) {
          top = y;
          found = true;
          break;
        }
      }
      if (found) break;
    }

    // Find bottom boundary
    for (let y = canvas.height - 1; y >= 0; y--) {
      let found = false;
      for (let x = 0; x < canvas.width; x++) {
        if (!isWhite(x, y)) {
          bottom = y;
          found = true;
          break;
        }
      }
      if (found) break;
    }

    // Find left boundary
    for (let x = 0; x < canvas.width; x++) {
      let found = false;
      for (let y = 0; y < canvas.height; y++) {
        if (!isWhite(x, y)) {
          left = x;
          found = true;
          break;
        }
      }
      if (found) break;
    }

    // Find right boundary
    for (let x = canvas.width - 1; x >= 0; x--) {
      let found = false;
      for (let y = 0; y < canvas.height; y++) {
        if (!isWhite(x, y)) {
          right = x;
          found = true;
          break;
        }
      }
      if (found) break;
    }

    const newWidth = right - left + 1;
    const newHeight = bottom - top + 1;
    const trimmedCanvas = document.createElement('canvas');
    const trimmedCtx = trimmedCanvas.getContext('2d');
    trimmedCanvas.width = newWidth;
    trimmedCanvas.height = newHeight;

    trimmedCtx.drawImage(canvas, left, top, newWidth, newHeight, 0, 0, newWidth, newHeight);

    return trimmedCanvas.toDataURL();
  };

  static resizeImage(imageUrl, width, height) {
    return new Promise((resolve, reject) => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      const img = new Image();

      img.crossOrigin = 'Anonymous';
      img.src = imageUrl;

      img.onload = function () {
        canvas.width = width;
        canvas.height = height;

        ctx.drawImage(img, 0, 0, width, height);
        const format = Utility.browserSupportsWebP() ? 'image/webp' : 'image/png';
        const resizedImageUrl = canvas.toDataURL(format, 0.98);

        resolve(resizedImageUrl);
      };

      img.onerror = function () {
        reject(new Error('Failed to load image.'));
      };
    });
  }

  static browserSupportsWebP = () => {
    const canvas = document.createElement('canvas');
    if (canvas.getContext && canvas.getContext('2d')) {
      return canvas.toDataURL('image/webp').indexOf('data:image/webp') === 0;
    }
    return false;
  };

  static loadImage = (src) => {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.onload = () => resolve(image);
      image.onerror = reject;
      image.src = src;
    });
  };

  static addImageToOtherImage = async (originalImage, toAdd, position, width = -1, height = -1) => {
    const originalPromise = Utility.loadImage(originalImage);
    const toAddPromise = toAdd.startsWith('#') ? Promise.resolve() : Utility.loadImage(toAdd);
    const images = await Promise.all([originalPromise, toAddPromise]);
    const [original, add] = images;
    let { left, top, right, bottom } = position;

    // set values according to the input position and provided width and height
    if (width < 0) width = add.width;
    if (height < 0) height = add.height;
    if (right && !left) left = original.width - (right + width);
    if (bottom && !top) top = original.height - (bottom + height);

    if (top && !bottom) bottom = top + height;
    if (left && !right) right = left + width;

    // translate the position to the top-left corner
    if (right < 0) {
      left = left + right;
      right = 0;
    }
    if (bottom < 0) {
      top = top + bottom;
      bottom = 0;
    }
    if (left < 0) {
      right = right - left;
      left = 0;
    }
    if (top < 0) {
      bottom = bottom - top;
      top = 0;
    }

    width = original.width - right - left;
    height = original.height - bottom - top;

    const positionX = left;
    const positionY = top;

    const canvas = document.createElement('canvas');
    canvas.width = Math.max(original.width, right, original.width - left);
    canvas.height = Math.max(original.height, bottom, original.height - top);

    canvas.width = original.width;
    canvas.height = original.height;

    const ctx = canvas.getContext('2d');
    ctx.drawImage(original, 0, 0);
    if (toAdd.startsWith('#')) {
      const radius = 8;
      ctx.fillStyle = toAdd;
      ctx.lineWidth = 0;
      ctx.strokeStyle = toAdd;
      ctx.beginPath();
      ctx.moveTo(positionX + radius, positionY);
      ctx.lineTo(positionX + width - radius, positionY);
      ctx.arcTo(positionX + width, positionY, positionX + width, positionY + radius, radius);
      ctx.lineTo(positionX + width, positionY + height - radius);
      ctx.arcTo(positionX + width, positionY + height, positionX + width - radius, positionY + height, radius);
      ctx.lineTo(positionX + radius, positionY + height);
      ctx.arcTo(positionX, positionY + height, positionX, positionY + height - radius, radius);
      ctx.lineTo(positionX, positionY + radius);
      ctx.arcTo(positionX, positionY, positionX + radius, positionY, radius);
      ctx.closePath();
      ctx.fill();
      ctx.stroke();
    } else {
      ctx.drawImage(add, positionX, positionY, width, height);
    }
    return canvas.toDataURL();
  };

  static getImageDimensions = (dataURL) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        resolve({ width: img.width, height: img.height });
      };
      img.onerror = reject;
      img.src = dataURL;
    });
  };

  static addTextToImage = async (dataURL, options) => {
    options = {
      fontSize: options?.fontSize ?? 16,
      alignTextHorizontally: options?.alignTextHorizontally ?? 'left',
      alignTextVertically: options?.alignTextVertically ?? 'top',
      text: options?.text ?? '',
      position: options?.position ?? { x: 0, y: 0 },
      color: options?.color ?? 'black',
      font: options?.font ?? 'Inter, sans-serif',
      shadowColor: options?.shadowColor ?? '#00000000',
      shadowBlur: options?.shadowBlur ?? 0,
      shadowOffsetX: options?.shadowOffsetX ?? 0,
      shadowOffsetY: options?.shadowOffsetY ?? 0,
    };
    const {
      fontSize,
      alignTextHorizontally,
      alignTextVertically,
      text,
      position,
      color,
      font,
      shadowColor,
      shadowBlur,
      shadowOffsetX,
      shadowOffsetY,
    } = options;

    const image = await Utility.loadImage(dataURL);

    const canvas = document.createElement('canvas');
    canvas.width = image.width;
    canvas.height = image.height;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(image, 0, 0);

    ctx.font = `${fontSize}px ${font}`;
    ctx.fillStyle = color;
    ctx.shadowColor = shadowColor;
    ctx.shadowBlur = shadowBlur;

    ctx.textAlign = alignTextHorizontally;
    ctx.textBaseline = alignTextVertically;

    ctx.shadowOffsetX = shadowOffsetX;
    ctx.shadowOffsetY = shadowOffsetY;
    ctx.fillText(text, position.x, position.y);
    return canvas.toDataURL();
  };
}
